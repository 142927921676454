.App {
  height: 100vh;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.books {
  display: flex;
  gap: 10px;
}

.book {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

img {
  width: 200px;
  height: 300px;
  object-fit: cover;
  background-color: aquamarine;
}

.delete {
  border: none;
  padding: 3px 6px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid rgb(245, 191, 191);
  color: rgb(242, 100, 100);
}

.update {
  border: none;
  padding: 3px 6px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 243);
  color: rgb(139, 139, 234);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input {
  width: 250px;
  padding: 10px;
  border: 1px solid gray;
}

.formButton {
  border: none;
  padding: 10px;
  background-color: rgb(244, 142, 74);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}